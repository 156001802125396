import React, { useState } from "react"

import { Stack } from "@kiwicom/orbit-components"

import { PayItemsSection } from "components/admin-human-resources/pay-items-section"
import {
  DetailsInner,
  DetailsWrapper,
} from "components/generic/multi-column-layout/styled-components"
import { DriversAdminLayout } from "components/layout-custom"

const Page = () => {
  const [refreshCounter, setRefreshCounter] = useState(0)

  return (
    <DriversAdminLayout title="Drivers Additional Payments" padded={false}>
      <DetailsWrapper fullScreen={false}>
        <DetailsInner fullScreen={false}>
          <Stack spacing="large">
            <PayItemsSection
              sectionTitle="Pending"
              audience="admin"
              showAllTime
              showRequestedOnly
              refreshCounter={refreshCounter}
              refreshFunc={() => setRefreshCounter(refreshCounter + 1)}
            />
            <PayItemsSection
              sectionTitle="All"
              audience="admin"
              showAddButton
              refreshCounter={refreshCounter}
              refreshFunc={() => setRefreshCounter(refreshCounter + 1)}
            />
          </Stack>
        </DetailsInner>
      </DetailsWrapper>
    </DriversAdminLayout>
  )
}

export default Page
